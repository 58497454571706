$body-bg: #1c1c1c;
$body-text: #cecece;

$secondary: #343434;
$secondary-dark: #181818;
$secondary-border: #4d4c4c;
$secondary-dark-border: #2a2a2a;

$accent: #a9d094;
$muted: #8f8f8f;

$shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
